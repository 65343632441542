export default defineNuxtPlugin((nuxtApp) => {
  const ssrEnvs = useState('ssr-envs', () => ({}) as Record<string, string>);

  if (!import.meta.server || import.meta.env.VITE_PASSPORT_ENV === 'prod' || import.meta.env.VITE_PASSPORT_ENV === 'preus') {
    console.log('[ssr-envs]', ssrEnvs.value);
    return;
  }

  const headers = nuxtApp?.ssrContext?.event.headers;
  if (!headers) {
    return;
  }

  for (const [key, value] of headers.entries()) {
    ssrEnvs.value[key] = value;
  }
});
