import { isEmpty, isNil, isString } from 'lodash-es';
import { Layouts } from '~/stores/layout';

export default defineNuxtRouteMiddleware(async (to) => {
  // url变更，服务器做重定向
  if (to.path.includes('/artimind-ai')) {
    return navigateTo({
      path: '/aimake',
      query: {
        ...to.query,
      },
    });
  }

  // 是否已登录
  const isLogin = !isNil(getUToken());
  // 是否需要登录校验
  const needLogin = !isLogin && (to.meta?.auth || to.query?.login === 'true' || matchAuthRoute(to.path));

  // 重定向到首页
  if (
    needLogin &&
    import.meta.server &&
    !['/', '/explore/featured'].includes(to.path) // 不是前往首页
  ) {
    return navigateTo({
      path: '/',
      query: {
        login: 'true',
        redirect: to.fullPath,
      },
    });
  }

  // 阻止跳转并打开登录弹窗
  if (needLogin && import.meta.client) {
    const { $client } = useNuxtApp();
    const redirect = parserRedirect();
    $client.openModal(({ reload }) => {
      isString(redirect) ? reload(redirect) : reload();
    });
    return abortNavigation();
  }

  // 应用布局
  if (isInWujie() || matchUseCommonLayoutRoute(to.path)) {
    setPageLayout(Layouts.Common);
  } else if (to.meta?.layout !== undefined) {
    setPageLayout(to.meta?.layout);
  } else {
    setPageLayout(Layouts.Default);
  }

  // 访问未定义路由时
  if (!to.matched.length) {
    return abortNavigation();
  }
});

const parserRedirect = () => {
  const params = new URLSearchParams(window.location.search);

  const redirect = params.get('redirect');

  params.delete('login');
  params.delete('redirect');

  const query = params.toString();
  const path = window.location.pathname;
  const url = !isEmpty(query) ? `${path}?${query}` : path;
  window.history.replaceState(null, 'null', url);

  return redirect;
};

// 没有header的page
// const wujieRoutes = ['/user-center/bookmarks'];
const useCommonLayoutRoutes = ['/order/', '/order/detail/', '/point-history', '/xart-usage', '/credits/purchase', '/studio-all'];
function matchUseCommonLayoutRoute(path: string) {
  return useCommonLayoutRoutes.some((r) => path.includes(r));
}

// 客户端需要登录的路由
const authRoutes = ['/creator/center', '/share/project', '/share/bundle', '/share/designs'];
function matchAuthRoute(path: string) {
  return authRoutes.some((r) => path.startsWith(r));
}
