export function useForceLogin() {
  const isForceLogin = useStorage('force-login', false);
  const { $client } = useNuxtApp();

  const markForceLogin = () => {
    isForceLogin.value = true;
  };
  const forceLogin = () => {
    const _isForceLogin = isForceLogin.value;
    isForceLogin.value = true;
    if (!_isForceLogin && !getUToken()) {
      $client.login();
    }
  };
  return { forceLogin, markForceLogin, isForceLogin: computed(() => isForceLogin.value) };
}
