import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/root/workspace/xtool-community-ssr/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/root/workspace/xtool-community-ssr/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/root/workspace/xtool-community-ssr/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8 from "/root/workspace/xtool-community-ssr/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/root/workspace/xtool-community-ssr/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/root/workspace/xtool-community-ssr/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/root/workspace/xtool-community-ssr/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/root/workspace/xtool-community-ssr/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY from "/root/workspace/xtool-community-ssr/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/root/workspace/xtool-community-ssr/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/root/workspace/xtool-community-ssr/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/root/workspace/xtool-community-ssr/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/root/workspace/xtool-community-ssr/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/root/workspace/xtool-community-ssr/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/root/workspace/xtool-community-ssr/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import _01_passport_client_QsE0Vbr1T9oxuS3LYeNE6LecRrMQJx39o_5QYXRHxQ4 from "/root/workspace/xtool-community-ssr/plugins/01.passport.client.ts";
import _02_message_client_xygQHWWRcjwbiBPBRAYac4_SirXuDHym1zZQoND_RXs from "/root/workspace/xtool-community-ssr/plugins/02.message.client.ts";
import _03_msg_setting_client_L8jGnq1pZNaHS7jTcqy1xmUwJpLGIv7wzPIoKFWiDWM from "/root/workspace/xtool-community-ssr/plugins/03.msg-setting.client.ts";
import Lottie_client_J1ivcJcgGU9kjyXz9QrXuArQNxS783EZzd52LFhpFTM from "/root/workspace/xtool-community-ssr/plugins/Lottie.client.ts";
import atomm_pro__bDLsMmmnvtqZcbn7Y_unnqzwbYFQOZ66pJVUAXLXBE from "/root/workspace/xtool-community-ssr/plugins/atomm-pro.ts";
import atomm_ui_1S02LyfBWf_peQQIhsz6eS45vHVVg9lLc1p2L4CFozs from "/root/workspace/xtool-community-ssr/plugins/atomm-ui.ts";
import context_3Bx9cRfhdlncvWaY8iWOs_ekNxs2SuToYdj0XlNlaM8 from "/root/workspace/xtool-community-ssr/plugins/context.ts";
import cropper_client_RVYpbI6GunOk9KO5zrI4U9aXjWZqIJJ1fSiOPa_Ld5w from "/root/workspace/xtool-community-ssr/plugins/cropper.client.ts";
import directives_Y6D8IjviQKB3EteSmgkh6nr8GGru_rC_DjhQ3lLa_GM from "/root/workspace/xtool-community-ssr/plugins/directives.ts";
import host_env_RUQtiauJOCTxVZdqgGlaB5vM9qVJaB_DBKAwNKxb_kM from "/root/workspace/xtool-community-ssr/plugins/host-env.ts";
import iconFont_H7SaDUgxzdgsuK2HA9cmpACQbAR6baXB9quSHQUipzc from "/root/workspace/xtool-community-ssr/plugins/iconFont.ts";
import refresh_client_pUgqdPuV6yyeH619ynhZtp5G5sLzeiG7QcvXA0dJzmg from "/root/workspace/xtool-community-ssr/plugins/refresh.client.ts";
import sensors_client_Up_nM_2ZR01w3U6FI8AzQfH2RGiSc2VkbV3tSq7__EQ from "/root/workspace/xtool-community-ssr/plugins/sensors.client.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/root/workspace/xtool-community-ssr/plugins/sentry.client.ts";
import svga_client_TMJ_YQdky57sOROlWIe8ykf1QfzAxSi3CJhXgaiBkAI from "/root/workspace/xtool-community-ssr/plugins/svga.client.ts";
import worker_client_mdYBkTzjqZD2NNuE6qKqiGK7ZsRjwN1b9kH_1b5ZezA from "/root/workspace/xtool-community-ssr/plugins/worker.client.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/root/workspace/xtool-community-ssr/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  _01_passport_client_QsE0Vbr1T9oxuS3LYeNE6LecRrMQJx39o_5QYXRHxQ4,
  _02_message_client_xygQHWWRcjwbiBPBRAYac4_SirXuDHym1zZQoND_RXs,
  _03_msg_setting_client_L8jGnq1pZNaHS7jTcqy1xmUwJpLGIv7wzPIoKFWiDWM,
  Lottie_client_J1ivcJcgGU9kjyXz9QrXuArQNxS783EZzd52LFhpFTM,
  atomm_pro__bDLsMmmnvtqZcbn7Y_unnqzwbYFQOZ66pJVUAXLXBE,
  atomm_ui_1S02LyfBWf_peQQIhsz6eS45vHVVg9lLc1p2L4CFozs,
  context_3Bx9cRfhdlncvWaY8iWOs_ekNxs2SuToYdj0XlNlaM8,
  cropper_client_RVYpbI6GunOk9KO5zrI4U9aXjWZqIJJ1fSiOPa_Ld5w,
  directives_Y6D8IjviQKB3EteSmgkh6nr8GGru_rC_DjhQ3lLa_GM,
  host_env_RUQtiauJOCTxVZdqgGlaB5vM9qVJaB_DBKAwNKxb_kM,
  iconFont_H7SaDUgxzdgsuK2HA9cmpACQbAR6baXB9quSHQUipzc,
  refresh_client_pUgqdPuV6yyeH619ynhZtp5G5sLzeiG7QcvXA0dJzmg,
  sensors_client_Up_nM_2ZR01w3U6FI8AzQfH2RGiSc2VkbV3tSq7__EQ,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  svga_client_TMJ_YQdky57sOROlWIe8ykf1QfzAxSi3CJhXgaiBkAI,
  worker_client_mdYBkTzjqZD2NNuE6qKqiGK7ZsRjwN1b9kH_1b5ZezA,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]