import { useCreditService } from '@atomm/atomm-pro';
import { useForceLogin } from '~/composables/force-login';
import { useSensors } from '~/composables/sensors';
import { useUserInfo } from '~/stores/user';

export async function appInit() {
  // 埋点初始化
  traceInit();

  // 同步 xcs 路由
  syncXcsApp();

  // 引导用户注册
  guideRegister();

  // 初始化全局状态
  await initGlobalState();
}

function traceInit() {
  const { $client } = useNuxtApp();
  const { sensors }: any = useSensors();
  const { addCommonProperty } = useGA4Tracking();
  onMounted(async () => {
    if (!$client.token) {
      return;
    }
    const user = await $client.fetchUserInfo();
    // 登录成功设置调用神策登录接口，将用户id与神策id关联。上线后替换 xtool_community_id
    sensors.loginWithKey(import.meta.env.VITE_SENSORS_ID_KEY, user.uid);

    // 设置ga4用户属性
    addCommonProperty('user_id', user.uuid);

    // 设置用户属性，存在则覆盖
    sensors.setProfile({
      name: user.userName,
      registered_email: user.email,
      xtool_community_id: user.uid,
      registered_time: user.createTime,
    });
  });
}

function syncXcsApp() {
  const route = useRoute();

  // passport内嵌站点发生路由的变化，需要上报一个路由改变的消息。
  watch(
    () => route.path,
    () => {
      window.parent.postMessage({ action: 'route-change' }, '*');
    },
  );

  // 监听xcs-app的reload-page事件，用于刷新页面
  const ACTION: any = {
    ['reload-page'](e: MessageEvent) {
      if (e.data.from === 'xcs-app' && isInIframe()) {
        window.location.reload();
      }
    },
  };
  const listenerReloadPage = (e: MessageEvent) => {
    const { action } = e.data;
    ACTION[action] && ACTION[action](e);
  };
  onMounted(() => {
    window.addEventListener('message', listenerReloadPage);
  });
  onUnmounted(() => {
    window.removeEventListener('message', listenerReloadPage);
  });
}

function guideRegister() {
  const route = useRoute();

  // 未注册用户强制弹出登录弹窗
  const { forceLogin } = useForceLogin();
  onMounted(() => {
    if (route.path !== '/' && route.path !== '/explore/featured') {
      setTimeout(forceLogin, 3000);
    }
  });

  // 新用户提示
  if (import.meta.client && getUToken() && localStorage.getItem('new-user-toast')) {
    localStorage.removeItem('new-user-toast');
    message.success('Obtained 200 credits successfully. Use it now!');
  }
}

async function initGlobalState() {
  const inited = useState('GLOBAL_USER', () => false);

  const route = useRoute();

  // 初始化用户信息
  const userInfoStore = useUserInfo();

  const creditService = useCreditService();

  const isHomePage = computed(() => ['/', '/explore/featured'].includes(route.path));

  const init = async () => {
    if ((inited.value && !isInClient()) || !getUToken()) {
      return;
    }
    try {
      await Promise.all([userInfoStore.init(), creditService.init()]);
    } finally {
      inited.value = true;
    }
  };

  // 首页加了缓存需要特殊处理
  if (!isHomePage.value) {
    await init();
  } else {
    onMounted(init);
  }
}
