import { type AtommUser, type Envs, AtommProContext, AtommProDomain } from '@atomm/atomm-pro';
import { useUserInfo } from '~/stores/user';
import { useGA4Tracking } from '~/composables/useGA4Tracking';

export default defineNuxtPlugin(() => {
  const userInfo = useUserInfo();
  const { sendEvent } = useGA4Tracking();
  const user = computed(() => ({
    userName: userInfo.myInfo.name,
    avatar: getOssProcessedImage(userInfo.myInfo.headpic.split('?')[0], 'm_fill,w_100,h_100', 'webp'),
    isPremiumCreator: userInfo.isSaleMaker,
    pointCnt: userInfo.shopifyUserInfo.points,
  }));
  AtommProContext.use({
    env: import.meta.env.VITE_ATOMM_ENV as Envs,
    domain: AtommProDomain.COMMUNITY,
    user: user as unknown as Ref<AtommUser>,
    utoken: useMiddleCookie('utoken') as Ref<string>,
    reportTrace: sendEvent as any,
  });
});
