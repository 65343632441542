import favicon from '/favicon.ico';

export const headInfo = {
  titleTemplate: 'Atomm | Find Free Laser Cut Files for Any Craft Templates.',
  description:
    'Discover high-quality and free SVG, PNG, and DXF files for all your craft templates at Atomm. Explore a variety of templates perfect for laser cutting, DIY crafts, and creative inspiration.',
  content: 'A vibrant hub for inspirational tutorials and a treasure trove of design resources. Explore 6000+ laser templates and 1000+ SVGs.',
  ogImage: 'https://image-res.xtool.com/resource/xtool/community/static/share_default_0717.png',
  favicon,
};

export const defaultSeo = {
  titleTemplate: headInfo.titleTemplate,
  link: [
    {
      rel: 'shortcut icon',
      type: 'image/x-icon',
      href: favicon,
    },
  ],
  meta: [
    { name: 'title', content: headInfo.titleTemplate },
    {
      name: 'h1',
      content: 'Atomm Center',
    },
    {
      name: 'description',
      content: headInfo.description,
    },
    {
      property: 'og:title',
      content: headInfo.titleTemplate,
    },
    {
      property: 'og:description',
      content: headInfo.description,
    },
    {
      property: 'og:image',
      content: headInfo.ogImage,
    },
  ],
};
