import { defineAsyncComponent } from 'vue'
export default {
  common: defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/common.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/default.vue").then(m => m.default || m)),
  "default-footer": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/footer.vue").then(m => m.default || m)),
  "default-header-hooks": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/hooks/index.ts").then(m => m.default || m)),
  "default-header-hooks-navs": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/hooks/navs.ts").then(m => m.default || m)),
  "default-header-hooks-types": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/hooks/types.ts").then(m => m.default || m)),
  "default-header": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/index.vue").then(m => m.default || m)),
  "default-header-mb-header-drawer": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/mb-header/Drawer.vue").then(m => m.default || m)),
  "default-header-mb-header-menu": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/mb-header/Menu.vue").then(m => m.default || m)),
  "default-header-mb-header-navs": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/mb-header/Navs.vue").then(m => m.default || m)),
  "default-header-mb-header": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/mb-header/index.vue").then(m => m.default || m)),
  "default-header-pc-header-create-guide": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/CreateGuide.vue").then(m => m.default || m)),
  "default-header-pc-header-drawer": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/Drawer.vue").then(m => m.default || m)),
  "default-header-pc-header-dropdown": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/Dropdown.vue").then(m => m.default || m)),
  "default-header-pc-header-message": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/Message.vue").then(m => m.default || m)),
  "default-header-pc-header-navs": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/Navs.vue").then(m => m.default || m)),
  "default-header-pc-header-search": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/Search.vue").then(m => m.default || m)),
  "default-header-pc-header-share": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/Share.vue").then(m => m.default || m)),
  "default-header-pc-header-studio": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/Studio.vue").then(m => m.default || m)),
  "default-header-pc-header-user": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/User.vue").then(m => m.default || m)),
  "default-header-pc-header": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/index.vue").then(m => m.default || m)),
  "default-header-pc-header-menu-df-menu": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/menu/DfMenu.vue").then(m => m.default || m)),
  "default-header-pc-header-menu-df-menu-item": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/menu/DfMenuItem.vue").then(m => m.default || m)),
  "default-header-pc-header-menu-types": defineAsyncComponent(() => import("/root/workspace/xtool-community-ssr/layouts/default/header/pc-header/menu/types.ts").then(m => m.default || m))
}