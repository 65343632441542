import { initConfig } from '@makeblock/message-client-vue';
import { UaaClient } from '~/utils/uaa';

export default defineNuxtPlugin(() => {
  const $client = new UaaClient();
  // 初始化 message-client 落地页和设置页的接口配置
  initConfig({
    appCode: $client.client.platform,
    env: $client.client.env as any,
    utoken: $client.client.token,
  });
  return {
    provide: {
      client: $client,
    },
  };
});
