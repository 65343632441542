
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexXaYm1qct9pEjPew7BmiTmxTUFUDgzREts6C4_45PfAXe8Meta } from "/root/workspace/xtool-community-ssr/pages/index.vue?macro=true";
import { default as indexuPD2X6gTWZ77ndBC6cKztjO7yB20N723uMbjl_Y5_45A4Meta } from "/root/workspace/xtool-community-ssr/pages/all/index.vue?macro=true";
import { default as allU4r2YUfTtfDF8_yQaOoFiwH8diK7qjGeZNoYubVvNbUMeta } from "/root/workspace/xtool-community-ssr/pages/column/all.vue?macro=true";
import { default as _91id_93gQCDY02STO5kgaeYpei8zbj7X66LmNLDvscTMYCWE44Meta } from "/root/workspace/xtool-community-ssr/pages/order/[id].vue?macro=true";
import { default as _91id_9355rkxHSiQ2BNNSJ6_458zsiRLm8rToD7h8UKZkIa6SGdAMeta } from "/root/workspace/xtool-community-ssr/pages/topic/[id].vue?macro=true";
import { default as _91id_93RfQRaaX_45WnmcVmQT_45qeixCkjsbtv8Vwjackovm1hnSoMeta } from "/root/workspace/xtool-community-ssr/pages/column/[id].vue?macro=true";
import { default as indexEd9H6ix8R5wrJK4Gn0zlbuAi_450JypFr_45oyhHktXFDI4Meta } from "/root/workspace/xtool-community-ssr/pages/aimake/index.vue?macro=true";
import { default as _91id_93xA0zYlioRWVhaBHugodM2wNLPniXqUB_45bVFEFa7DXxQMeta } from "/root/workspace/xtool-community-ssr/pages/profile/[id].vue?macro=true";
import { default as configfWvKBKsmmhac06_45fBR23ZQp6knpYbBjJwQvjSojd9fgMeta } from "/root/workspace/xtool-community-ssr/pages/studio/config.ts?macro=true";
import { default as index48ZD4QAtr_45rmL7ZHm2Z5yc8jNdRgDAsvv9MNUlrY2_45YMeta } from "/root/workspace/xtool-community-ssr/pages/studio/index.vue?macro=true";
import { default as indexlOW0YtvKk2r9SWBFDAP2q42YIA1h0CsiYS_bZsquVuoMeta } from "/root/workspace/xtool-community-ssr/pages/contest/index.vue?macro=true";
import { default as guidez0t8oUI98tdN9qjhpR_45W2541o1XjeI5j4j1anEQigDMMeta } from "/root/workspace/xtool-community-ssr/pages/creator/guide.vue?macro=true";
import { default as index5v1fwjsBU21Ws_DgrQT0QS2APXqmSKxHy7CpzCiGESIMeta } from "/root/workspace/xtool-community-ssr/pages/creator/index.vue?macro=true";
import { default as indexm02wW4ncB9jzI9zXS6tkAL83iIt1Sqh6nx019C_45wiZQMeta } from "/root/workspace/xtool-community-ssr/pages/easyset/index.vue?macro=true";
import { default as indexNIlTjZK5c_hEWtP47QgiIWnKXvSpzWaGSKAzBcgN5J4Meta } from "/root/workspace/xtool-community-ssr/pages/explore/index.vue?macro=true";
import { default as indexA3SqFZI_lLwIavVYoAg1hVQvR0GRlzo3xW5EGit0wXQMeta } from "/root/workspace/xtool-community-ssr/pages/premium/index.vue?macro=true";
import { default as indexM2fo0fhypExIdwBSdYDxfFdhqz_hgSaMmYKYc2wKgNUMeta } from "/root/workspace/xtool-community-ssr/pages/rewards/index.vue?macro=true";
import { default as useShareGBWfqASa7ahAxqN3_E3FozdkG7_52zWaBLGl6r63o1MMeta } from "/root/workspace/xtool-community-ssr/pages/share/useShare.ts?macro=true";
import { default as storehARf_oGZInwoSQfLD18YFi80qIW9HQWG6peRLqNLgrIMeta } from "/root/workspace/xtool-community-ssr/pages/creator/center/store.vue?macro=true";
import { default as contentx2GHoIlWNItIsUD2e2Qwp8RYSrXjXZNWfgvhwEiWgCoMeta } from "/root/workspace/xtool-community-ssr/pages/creator/center/content.vue?macro=true";
import { default as contestsJOKhbA5wCMHUDP8Pl6VdMo5wsvYbE_ZmZWGEioGAfCoMeta } from "/root/workspace/xtool-community-ssr/pages/creator/center/contests.vue?macro=true";
import { default as earnings_45xTAY90cLeM3YmbgT8dJNr6FF58jsDlWujW2ZQmXwfAMeta } from "/root/workspace/xtool-community-ssr/pages/creator/center/earnings.vue?macro=true";
import { default as copyright27hQ0LokiiAAW_45XHKYVNSiSRQGsOHdeuN8SIwWvi4x8Meta } from "/root/workspace/xtool-community-ssr/pages/creator/center/copyright.vue?macro=true";
import { default as dashboard6ntT5fIqQzDf63qNFJ2Kx5xTozBJPo2qtNiRl6WV764Meta } from "/root/workspace/xtool-community-ssr/pages/creator/center/dashboard.vue?macro=true";
import { default as center4Z0PfWQ1Wm5Rksx5LATcpfT1xPsfIzbMDE0Wvb8OpMMMeta } from "/root/workspace/xtool-community-ssr/pages/creator/center.vue?macro=true";
import { default as AIFilterEnZUZ1XyT9W4dmkX6l6YpRd3u2dKpzArpCQ6BCgyO88Meta } from "/root/workspace/xtool-community-ssr/pages/aimake/AIFilter.vue?macro=true";
import { default as _91id_93uxXIDGGTSFXsnW3eGCgQ6xlVVIUIL5Q6fGwuXULjeKQMeta } from "/root/workspace/xtool-community-ssr/pages/collection/[id].vue?macro=true";
import { default as useVirtual34jk5Qksx3s0CBPe_45i32i8zdEQcZN_A44ZMUcwz5hYsMeta } from "/root/workspace/xtool-community-ssr/pages/column/useVirtual.ts?macro=true";
import { default as purchaseqIfS_45Ife7rKGMrw_JoNHzItl4J_45vNRCCKc0hlChsXxQMeta } from "/root/workspace/xtool-community-ssr/pages/credits/purchase.vue?macro=true";
import { default as hookX1ASS1KOTlr7GUcJjRDM1TfGAGhdF_j9Hgvequ1vRaUMeta } from "/root/workspace/xtool-community-ssr/pages/designs-edit/hook.ts?macro=true";
import { default as indexshUN7ydUcozqdzFxI_4576xYS9XU0Tk08QFRhp0fuU3Q0Meta } from "/root/workspace/xtool-community-ssr/pages/discussion/index.vue?macro=true";
import { default as hookDp0n2DJnD_45s2sZu9TwzYkoF8nJl431GRdi0YdjqXy3kMeta } from "/root/workspace/xtool-community-ssr/pages/share/bundle/hook.ts?macro=true";
import { default as collectionDAROaowV7JtJrQA2PGaWHPRdKEzps_SoT4D9CWYbJJ0Meta } from "/root/workspace/xtool-community-ssr/pages/topic/collection.vue?macro=true";
import { default as indexFNtDQ9RB4YdmFfdXEbf73ulJNDbMxajVgPHZvhmz4KgMeta } from "/root/workspace/xtool-community-ssr/pages/xart-usage/index.vue?macro=true";
import { default as indexpgGIsOSW1zc1GJO4CN6xYcBtl_MoIGOdECVtJjxyUrgMeta } from "/root/workspace/xtool-community-ssr/pages/activity/ai/index.vue?macro=true";
import { default as AIUpscalernTbfGxoO8zExrW0oE7jiqBtMSdDgPdF_IaIRYhESd3gMeta } from "/root/workspace/xtool-community-ssr/pages/aimake/AIUpscaler.vue?macro=true";
import { default as index__45NYcg2W62U50_45eHZiFQ9D89NpSEt88_8UAcjbvmfpMMeta } from "/root/workspace/xtool-community-ssr/pages/aimake/home/index.vue?macro=true";
import { default as _91id_93sR1EOGRU4bnywYy9pacNFTLR_454EGeW9FVW3YPEbVC_458Meta } from "/root/workspace/xtool-community-ssr/pages/designs-edit/[id].vue?macro=true";
import { default as _91id_93ZVvF22LcRmg1LwetxDF8kCcfUlktjpneGMtxf6wcExsMeta } from "/root/workspace/xtool-community-ssr/pages/order/detail/[id].vue?macro=true";
import { default as indexLGCiAF3vZjPtmbNjv6hBnkFxR4ega_45aZUbCK0tGulxYMeta } from "/root/workspace/xtool-community-ssr/pages/preferences/index.vue?macro=true";
import { default as indexXOiLqhTGwTdH90Mtb_vm_fhC9772mnj3oo_iK517SUUMeta } from "/root/workspace/xtool-community-ssr/pages/project/pay/index.vue?macro=true";
import { default as hookMjf4hUk1c6QlCa9ZZti4N0j9vwVgZI3OBq62rl1jdOUMeta } from "/root/workspace/xtool-community-ssr/pages/share/project/hook.ts?macro=true";
import { default as indexBOIjPREJgCxBP7vTdYg4STI_fYDxO59Wq6AqgM4i8G8Meta } from "/root/workspace/xtool-community-ssr/pages/user-center/index.vue?macro=true";
import { default as _91id_93uc0l8DBAjTYI34PD3CfYHgrwZAH_457w5drmb_C5sAj0IMeta } from "/root/workspace/xtool-community-ssr/pages/activity/list/[id].vue?macro=true";
import { default as _91id_939Jj_45i5_45yTMNk5Vo0_45U41LnjlTZzoxMZpxTPbV4GNSdoMeta } from "/root/workspace/xtool-community-ssr/pages/activity/post/[id].vue?macro=true";
import { default as _91id_93nTJmySe8z1GbMpu_yeD4RGWlpKPiS7GaboCCFVTw2_45IMeta } from "/root/workspace/xtool-community-ssr/pages/easyset/press/[id].vue?macro=true";
import { default as indexgilmx1R2jyhnih_45aUdmim2_JUpa1AjDuGMilX3FUfDMMeta } from "/root/workspace/xtool-community-ssr/pages/share/bundle/index.vue?macro=true";
import { default as indexeNQVSNVOHBjJWxBxKvOcvKaTya3FRPW3yu4EvqLA6foMeta } from "/root/workspace/xtool-community-ssr/pages/activity/xArt/index.vue?macro=true";
import { default as indexGVDJOtPtjidOk8WDVnaeeolAzfFioBSYaxn0_45fSA6RIMeta } from "/root/workspace/xtool-community-ssr/pages/creator/apply/index.vue?macro=true";
import { default as indexXc0QhlsXJMt2S4Q9mnpvjB0hPbd8efkS3DjfFKuNE9UMeta } from "/root/workspace/xtool-community-ssr/pages/notice-center/index.vue?macro=true";
import { default as indexvS_45HcFRp7VfykoU1es1f82Ev9r7m4HUi9wH2LSySLZ4Meta } from "/root/workspace/xtool-community-ssr/pages/point-history/index.vue?macro=true";
import { default as index5OrpYjDGsaM_lrhVDvJsYKt7ZImzrTh1fWrgQBwaNZoMeta } from "/root/workspace/xtool-community-ssr/pages/search-result/index.vue?macro=true";
import { default as indexX51Eyj46tbt0tiNuNC8WfPjFfCbcNpDdsjtxgksDwBYMeta } from "/root/workspace/xtool-community-ssr/pages/share/project/index.vue?macro=true";
import { default as indexHXDJrVNuBmBrTIDZ397Rjyo6HNH0RCK5yNqH9ImfEfgMeta } from "/root/workspace/xtool-community-ssr/pages/share/success/index.vue?macro=true";
import { default as pricingRhL9RAEKYZsWfN3C_wiisFqbU5_45W8MVzAoM_45woKYZ8EMeta } from "/root/workspace/xtool-community-ssr/pages/subscription/pricing.vue?macro=true";
import { default as indexJ8zDcTA4oqh0C2FiA9bNGBqchrMLCiTPEg4iVxE1_45ssMeta } from "/root/workspace/xtool-community-ssr/pages/aimake/generate/index.vue?macro=true";
import { default as _91id_93aSFRsyzaMSiwClOGzQth6_58cyK_45rQRxEL8i_tdhX9sMeta } from "/root/workspace/xtool-community-ssr/pages/aimake/navigator/[id].vue?macro=true";
import { default as indexTvkHc7r_bv0ExYD4mXB3Pa_1fP_451c90N_45mwb6T4fKJkMeta } from "/root/workspace/xtool-community-ssr/pages/contest/TopMain/index.vue?macro=true";
import { default as indexlZC2_aDhBcUTzPJ5Wz9KXTWsT3n6Clyoqw1YnfOwMQoMeta } from "/root/workspace/xtool-community-ssr/pages/discussion/edit/index.vue?macro=true";
import { default as bookmarksgigYhxVPvpNxrCs002yLr75fclHtUte4C5bBWsoZonUMeta } from "/root/workspace/xtool-community-ssr/pages/user-center/bookmarks.vue?macro=true";
import { default as _91_91id_93_931prFHIf050p1ogU1NnvLfXwzRNGffnO5vUqnx64mwfsMeta } from "/root/workspace/xtool-community-ssr/pages/xthings/editor/[[id]].vue?macro=true";
import { default as indexTQ5Ym0KQ0l6EjUC9bAp3ZuUEXn_KOJEdxIQOjTPFcH4Meta } from "/root/workspace/xtool-community-ssr/pages/activity/success/index.vue?macro=true";
import { default as AILogoGeneratorhZhP2iLSBraxkGLisG0Ru3lm6DbygYMu_45P6szUGjZ5YMeta } from "/root/workspace/xtool-community-ssr/pages/aimake/AILogoGenerator.vue?macro=true";
import { default as VectorGeneratorIvJclChujcqEgUSX0x3vbIq0nWrCiMn3DLqaXGB2Qt0Meta } from "/root/workspace/xtool-community-ssr/pages/aimake/VectorGenerator.vue?macro=true";
import { default as _91id_93TZ4wGvuFsYqf9g5n3T8QKTFGV2ilHjE28xI6aixu6UIMeta } from "/root/workspace/xtool-community-ssr/pages/easyset/materials/[id].vue?macro=true";
import { default as constbhRJ4gTwvFAT3jtfXqbPjIC_l_45tp5mZc34qMny0mfxUMeta } from "/root/workspace/xtool-community-ssr/pages/easyset/materials/const.ts?macro=true";
import { default as indexxZqNJtEa1knFH11PKqntWNRyQF3uqFxhV_R436IwWVQMeta } from "/root/workspace/xtool-community-ssr/pages/explore/featured/index.vue?macro=true";
import { default as indexKug_451LXKHE9PfecD0mJSlc50JNDwtqTKuBt0HFOY1VcMeta } from "/root/workspace/xtool-community-ssr/pages/explore/machines/index.vue?macro=true";
import { default as ColorfulPrintingffTHzALXq_MjNfEsAAt8aArRBqrWHa6HOBjZu1EWTwgMeta } from "/root/workspace/xtool-community-ssr/pages/aimake/ColorfulPrinting.vue?macro=true";
import { default as index87AbfSUfErgU03t447qGwnIR0gaLftqvJP3fdoyt6eQMeta } from "/root/workspace/xtool-community-ssr/pages/audit/[type]/[id]/index.vue?macro=true";
import { default as _91type_936502QlQeXQelzUBKuwofIgIyrtQsbzABSoxBOuzWQrUMeta } from "/root/workspace/xtool-community-ssr/pages/creator/earnings/[type].vue?macro=true";
import { default as indexOVx0RAiPDYkvyJZUIKxr0TBaMarEJRL220Q2i_VPApMMeta } from "/root/workspace/xtool-community-ssr/pages/discussion/detail/index.vue?macro=true";
import { default as indexX_2zEcZlc4RichsPGW_Tw4UF6DyAkZS2OQs_PPTCbHMMeta } from "/root/workspace/xtool-community-ssr/pages/explore/materials/index.vue?macro=true";
import { default as indexo5oQpsXSwYx_sgelfePdDmb0Y6c8XT_45sKzYv7UvBaSgMeta } from "/root/workspace/xtool-community-ssr/pages/explore/occasions/index.vue?macro=true";
import { default as useFormIntegrationi8aWNc42csn0lFj_45z7A4Yfzfm0IGCuKuE7Bfa_Q64r4Meta } from "/root/workspace/xtool-community-ssr/pages/share/useFormIntegration.ts?macro=true";
import { default as pay_45resultF_45QYpJcIjhfyx0hV8KgdkiTteAJaJ8h07zbpvOKYOW8Meta } from "/root/workspace/xtool-community-ssr/pages/subscription/pay-result.vue?macro=true";
import { default as indexWzk7VHvO5tTIVUrO10MxEh00xPW0Wwr8DjuGp97aoNIMeta } from "/root/workspace/xtool-community-ssr/pages/activity/christmas/index.vue?macro=true";
import { default as index4GDZvSuvvnqLNoLY1QgFXPrgTl04FtyoaF9p72TlHOwMeta } from "/root/workspace/xtool-community-ssr/pages/activity/halloween/index.vue?macro=true";
import { default as indexFnZOzRx4ZSHdWp6jww9iCtLzKBuY_3McwvjwWySO6YcMeta } from "/root/workspace/xtool-community-ssr/pages/activity/valentine/index.vue?macro=true";
import { default as indexFOjO79ECuMRBSnezhbZApMWtU8howM6y0Fp5A_rdow8Meta } from "/root/workspace/xtool-community-ssr/pages/activity/mothersday/index.vue?macro=true";
import { default as allcollectionsyBiDQWbW8DHP2Vl_45twe0R9ErXkHTy7MRxKRPwnrjIqIMeta } from "/root/workspace/xtool-community-ssr/pages/collection/allcollections.vue?macro=true";
import { default as indexUv_PXanjM4musNn0St0C3q81a9gP_45w4SZTAhllUK_4584Meta } from "/root/workspace/xtool-community-ssr/pages/contest/ContentMain/index.vue?macro=true";
import { default as indexdvnBByoafz9JmfHHXR_FcnHYLBLUcPyA8dhZSyalIRYMeta } from "/root/workspace/xtool-community-ssr/pages/activity/weeklytasks/index.vue?macro=true";
import { default as AIBackgroundRemoverBZizP9vQO_45ILg2aFtmqFvlmzAc5WNShdv6SGAcTafDUMeta } from "/root/workspace/xtool-community-ssr/pages/aimake/AIBackgroundRemover.vue?macro=true";
import { default as indexrp_45YojYHx7S7QvdGOAKE0m8bLOc5zNNFsmbuvGuRiw8Meta } from "/root/workspace/xtool-community-ssr/pages/activity/bestprojects/index.vue?macro=true";
import { default as indexCfUwqU_45JK175ihl_45Qpmo447eyO9F_45tJBjwn_7_453G080Meta } from "/root/workspace/xtool-community-ssr/pages/activity/thanksgiving/index.vue?macro=true";
import { default as ThreeDEffectEngravingWaasJXvyQVMFuzzGOpvSj8NwyFpmxaMLBe6_45cpIl60wMeta } from "/root/workspace/xtool-community-ssr/pages/aimake/ThreeDEffectEngraving.vue?macro=true";
import { default as indexplX8UrxfQBTAhOYkcE_ZFgtB6D_45FQEQQLvKwcKTDy_45YMeta } from "/root/workspace/xtool-community-ssr/pages/activity/christmas-2023/index.vue?macro=true";
import { default as _91id_93UgrDgSLKbiwo7NHDHOOt_459SkPr_zO_45HBPuobd_01jHYMeta } from "/root/workspace/xtool-community-ssr/pages/easyset/materials-shared/[id].vue?macro=true";
import { default as constt6h1hfTsRGCfCrHHk8mO92Fiu7LBEGJaE_45zFnATK7aMMeta } from "/root/workspace/xtool-community-ssr/pages/easyset/materials-shared/const.ts?macro=true";
import { default as hookbtfCS3ghrX9_45mDqRu0f4FbJBynVdV3PS6WI9vjLjYlYMeta } from "/root/workspace/xtool-community-ssr/pages/activity/thanksgiving-draw/hook.ts?macro=true";
import { default as _91id_93WTpHgj_7fveeLrTkNKQhErZUfALvE97L5MgljVNFW0oMeta } from "/root/workspace/xtool-community-ssr/pages/discussion/reply/[replyId]/[id].vue?macro=true";
import { default as indexqmmVmtm2HS9nYMvaWseQR_Si6FoBup7eQ23E4KWT_45CcMeta } from "/root/workspace/xtool-community-ssr/pages/activity/thanksgiving-draw/index.vue?macro=true";
import { default as indexTPDFeAJA07nC6ivHmFZWSMQABl0NJ3_Ei7_45ZNZWna9MMeta } from "/root/workspace/xtool-community-ssr/pages/contest/SingleActivityItem/index.vue?macro=true";
import { default as indextcqGfaXC_jd_45BlU_DMHGVjaZjMkn2PuwqmVlFOPBPbkMeta } from "/root/workspace/xtool-community-ssr/pages/project/detail/[type]/[id]/index.vue?macro=true";
import { default as indexub4J2lw0g7gZn2tzoKZ8_45DoWfTjZDBe0BmghCjNb_45AkMeta } from "/root/workspace/xtool-community-ssr/pages/activity/derivative-creation/index.vue?macro=true";
import { default as index2ozHljHA_45Jnd0EvRQ4brOVf8JXevcwLNy_i1RG0_GX4Meta } from "/root/workspace/xtool-community-ssr/pages/easyset/studio-material-list/index.vue?macro=true";
import { default as _91confId_93lDFymgM7pPJuktJ8Ll0EsJznFY9YIPhvxWYvr7HdwwIMeta } from "/root/workspace/xtool-community-ssr/pages/activity/temp-page/[actId]/[confId].vue?macro=true";
import { default as _91commentId_93gioe5GC973zQKyLwsNmP9WxmDTSRqZgsMAfUr2n0tpQMeta } from "/root/workspace/xtool-community-ssr/pages/project/detail/[type]/[id]/comment/[commentId].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/root/workspace/xtool-community-ssr/pages/index.vue")
  },
  {
    name: "all",
    path: "/all",
    component: () => import("/root/workspace/xtool-community-ssr/pages/all/index.vue")
  },
  {
    name: "column-all",
    path: "/column/all",
    component: () => import("/root/workspace/xtool-community-ssr/pages/column/all.vue")
  },
  {
    name: "order-id",
    path: "/order/:id()",
    meta: _91id_93gQCDY02STO5kgaeYpei8zbj7X66LmNLDvscTMYCWE44Meta || {},
    component: () => import("/root/workspace/xtool-community-ssr/pages/order/[id].vue")
  },
  {
    name: "topic-id",
    path: "/topic/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/topic/[id].vue")
  },
  {
    name: "column-id",
    path: "/column/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/column/[id].vue")
  },
  {
    name: "aimake",
    path: "/aimake",
    meta: indexEd9H6ix8R5wrJK4Gn0zlbuAi_450JypFr_45oyhHktXFDI4Meta || {},
    component: () => import("/root/workspace/xtool-community-ssr/pages/aimake/index.vue")
  },
  {
    name: "profile-id",
    path: "/profile/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/profile/[id].vue")
  },
  {
    name: "studio-config",
    path: "/studio/config",
    component: () => import("/root/workspace/xtool-community-ssr/pages/studio/config.ts")
  },
  {
    name: "studio",
    path: "/studio",
    meta: index48ZD4QAtr_45rmL7ZHm2Z5yc8jNdRgDAsvv9MNUlrY2_45YMeta || {},
    component: () => import("/root/workspace/xtool-community-ssr/pages/studio/index.vue")
  },
  {
    name: "contest",
    path: "/contest",
    component: () => import("/root/workspace/xtool-community-ssr/pages/contest/index.vue")
  },
  {
    name: "creator-guide",
    path: "/creator/guide",
    component: () => import("/root/workspace/xtool-community-ssr/pages/creator/guide.vue")
  },
  {
    name: "creator",
    path: "/creator",
    component: () => import("/root/workspace/xtool-community-ssr/pages/creator/index.vue")
  },
  {
    name: "easyset",
    path: "/easyset",
    component: () => import("/root/workspace/xtool-community-ssr/pages/easyset/index.vue")
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/root/workspace/xtool-community-ssr/pages/explore/index.vue")
  },
  {
    name: "premium",
    path: "/premium",
    component: () => import("/root/workspace/xtool-community-ssr/pages/premium/index.vue")
  },
  {
    name: "rewards",
    path: "/rewards",
    component: () => import("/root/workspace/xtool-community-ssr/pages/rewards/index.vue")
  },
  {
    name: "share-useShare",
    path: "/share/useShare",
    component: () => import("/root/workspace/xtool-community-ssr/pages/share/useShare.ts")
  },
  {
    name: "creator-center",
    path: "/creator/center",
    component: () => import("/root/workspace/xtool-community-ssr/pages/creator/center.vue"),
    children: [
  {
    name: "creator-center-store",
    path: "store",
    component: () => import("/root/workspace/xtool-community-ssr/pages/creator/center/store.vue")
  },
  {
    name: "creator-center-content",
    path: "content",
    component: () => import("/root/workspace/xtool-community-ssr/pages/creator/center/content.vue")
  },
  {
    name: "creator-center-contests",
    path: "contests",
    component: () => import("/root/workspace/xtool-community-ssr/pages/creator/center/contests.vue")
  },
  {
    name: "creator-center-earnings",
    path: "earnings",
    component: () => import("/root/workspace/xtool-community-ssr/pages/creator/center/earnings.vue")
  },
  {
    name: "creator-center-copyright",
    path: "copyright",
    component: () => import("/root/workspace/xtool-community-ssr/pages/creator/center/copyright.vue")
  },
  {
    name: "creator-center-dashboard",
    path: "dashboard",
    component: () => import("/root/workspace/xtool-community-ssr/pages/creator/center/dashboard.vue")
  }
]
  },
  {
    name: "aimake-AIFilter",
    path: "/aimake/AIFilter",
    component: () => import("/root/workspace/xtool-community-ssr/pages/aimake/AIFilter.vue")
  },
  {
    name: "collection-id",
    path: "/collection/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/collection/[id].vue")
  },
  {
    name: "column-useVirtual",
    path: "/column/useVirtual",
    component: () => import("/root/workspace/xtool-community-ssr/pages/column/useVirtual.ts")
  },
  {
    name: "credits-purchase",
    path: "/credits/purchase",
    component: () => import("/root/workspace/xtool-community-ssr/pages/credits/purchase.vue")
  },
  {
    name: "designs-edit-hook",
    path: "/designs-edit/hook",
    component: () => import("/root/workspace/xtool-community-ssr/pages/designs-edit/hook.ts")
  },
  {
    name: "discussion",
    path: "/discussion",
    component: () => import("/root/workspace/xtool-community-ssr/pages/discussion/index.vue")
  },
  {
    name: "share-bundle-hook",
    path: "/share/bundle/hook",
    component: () => import("/root/workspace/xtool-community-ssr/pages/share/bundle/hook.ts")
  },
  {
    name: "topic-collection",
    path: "/topic/collection",
    component: () => import("/root/workspace/xtool-community-ssr/pages/topic/collection.vue")
  },
  {
    name: "xart-usage",
    path: "/xart-usage",
    component: () => import("/root/workspace/xtool-community-ssr/pages/xart-usage/index.vue")
  },
  {
    name: "activity-ai",
    path: "/activity/ai",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/ai/index.vue")
  },
  {
    name: "aimake-AIUpscaler",
    path: "/aimake/AIUpscaler",
    component: () => import("/root/workspace/xtool-community-ssr/pages/aimake/AIUpscaler.vue")
  },
  {
    name: "aimake-home",
    path: "/aimake/home",
    meta: index__45NYcg2W62U50_45eHZiFQ9D89NpSEt88_8UAcjbvmfpMMeta || {},
    component: () => import("/root/workspace/xtool-community-ssr/pages/aimake/home/index.vue")
  },
  {
    name: "designs-edit-id",
    path: "/designs-edit/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/designs-edit/[id].vue")
  },
  {
    name: "order-detail-id",
    path: "/order/detail/:id()",
    meta: _91id_93ZVvF22LcRmg1LwetxDF8kCcfUlktjpneGMtxf6wcExsMeta || {},
    component: () => import("/root/workspace/xtool-community-ssr/pages/order/detail/[id].vue")
  },
  {
    name: "preferences",
    path: "/preferences",
    component: () => import("/root/workspace/xtool-community-ssr/pages/preferences/index.vue")
  },
  {
    name: "project-pay",
    path: "/project/pay",
    component: () => import("/root/workspace/xtool-community-ssr/pages/project/pay/index.vue")
  },
  {
    name: "share-project-hook",
    path: "/share/project/hook",
    component: () => import("/root/workspace/xtool-community-ssr/pages/share/project/hook.ts")
  },
  {
    name: "user-center",
    path: "/user-center",
    meta: indexBOIjPREJgCxBP7vTdYg4STI_fYDxO59Wq6AqgM4i8G8Meta || {},
    component: () => import("/root/workspace/xtool-community-ssr/pages/user-center/index.vue")
  },
  {
    name: "activity-list-id",
    path: "/activity/list/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/list/[id].vue")
  },
  {
    name: "activity-post-id",
    path: "/activity/post/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/post/[id].vue")
  },
  {
    name: "easyset-press-id",
    path: "/easyset/press/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/easyset/press/[id].vue")
  },
  {
    name: "share-bundle",
    path: "/share/bundle",
    component: () => import("/root/workspace/xtool-community-ssr/pages/share/bundle/index.vue")
  },
  {
    name: "activity-xArt",
    path: "/activity/xArt",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/xArt/index.vue")
  },
  {
    name: "creator-apply",
    path: "/creator/apply",
    component: () => import("/root/workspace/xtool-community-ssr/pages/creator/apply/index.vue")
  },
  {
    name: "notice-center",
    path: "/notice-center",
    meta: indexXc0QhlsXJMt2S4Q9mnpvjB0hPbd8efkS3DjfFKuNE9UMeta || {},
    component: () => import("/root/workspace/xtool-community-ssr/pages/notice-center/index.vue")
  },
  {
    name: "point-history",
    path: "/point-history",
    meta: indexvS_45HcFRp7VfykoU1es1f82Ev9r7m4HUi9wH2LSySLZ4Meta || {},
    component: () => import("/root/workspace/xtool-community-ssr/pages/point-history/index.vue")
  },
  {
    name: "search-result",
    path: "/search-result",
    component: () => import("/root/workspace/xtool-community-ssr/pages/search-result/index.vue")
  },
  {
    name: "share-project",
    path: "/share/project",
    component: () => import("/root/workspace/xtool-community-ssr/pages/share/project/index.vue")
  },
  {
    name: "share-success",
    path: "/share/success",
    component: () => import("/root/workspace/xtool-community-ssr/pages/share/success/index.vue")
  },
  {
    name: "subscription-pricing",
    path: "/subscription/pricing",
    component: () => import("/root/workspace/xtool-community-ssr/pages/subscription/pricing.vue")
  },
  {
    name: "aimake-generate",
    path: "/aimake/generate",
    meta: indexJ8zDcTA4oqh0C2FiA9bNGBqchrMLCiTPEg4iVxE1_45ssMeta || {},
    component: () => import("/root/workspace/xtool-community-ssr/pages/aimake/generate/index.vue")
  },
  {
    name: "aimake-navigator",
    path: "/aimake/navigator/:id()",
    meta: _91id_93aSFRsyzaMSiwClOGzQth6_58cyK_45rQRxEL8i_tdhX9sMeta || {},
    component: () => import("/root/workspace/xtool-community-ssr/pages/aimake/navigator/[id].vue")
  },
  {
    name: "contest-TopMain",
    path: "/contest/TopMain",
    component: () => import("/root/workspace/xtool-community-ssr/pages/contest/TopMain/index.vue")
  },
  {
    name: "discussion-edit",
    path: "/discussion/edit",
    component: () => import("/root/workspace/xtool-community-ssr/pages/discussion/edit/index.vue")
  },
  {
    name: "user-center-bookmarks",
    path: "/user-center/bookmarks",
    component: () => import("/root/workspace/xtool-community-ssr/pages/user-center/bookmarks.vue")
  },
  {
    name: "xthings-editor-id",
    path: "/xthings/editor/:id?",
    meta: _91_91id_93_931prFHIf050p1ogU1NnvLfXwzRNGffnO5vUqnx64mwfsMeta || {},
    component: () => import("/root/workspace/xtool-community-ssr/pages/xthings/editor/[[id]].vue")
  },
  {
    name: "activity-success",
    path: "/activity/success",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/success/index.vue")
  },
  {
    name: "aimake-AILogoGenerator",
    path: "/aimake/AILogoGenerator",
    component: () => import("/root/workspace/xtool-community-ssr/pages/aimake/AILogoGenerator.vue")
  },
  {
    name: "aimake-VectorGenerator",
    path: "/aimake/VectorGenerator",
    component: () => import("/root/workspace/xtool-community-ssr/pages/aimake/VectorGenerator.vue")
  },
  {
    name: "easyset-materials-id",
    path: "/easyset/materials/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/easyset/materials/[id].vue")
  },
  {
    name: "easyset-materials-const",
    path: "/easyset/materials/const",
    component: () => import("/root/workspace/xtool-community-ssr/pages/easyset/materials/const.ts")
  },
  {
    name: "explore-featured",
    path: "/explore/featured",
    component: () => import("/root/workspace/xtool-community-ssr/pages/explore/featured/index.vue")
  },
  {
    name: "explore-machines",
    path: "/explore/machines",
    component: () => import("/root/workspace/xtool-community-ssr/pages/explore/machines/index.vue")
  },
  {
    name: "aimake-ColorfulPrinting",
    path: "/aimake/ColorfulPrinting",
    component: () => import("/root/workspace/xtool-community-ssr/pages/aimake/ColorfulPrinting.vue")
  },
  {
    name: "audit-type-id",
    path: "/audit/:type()/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/audit/[type]/[id]/index.vue")
  },
  {
    name: "creator-earnings-type",
    path: "/creator/earnings/:type()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/creator/earnings/[type].vue")
  },
  {
    name: "discussion-detail",
    path: "/discussion/detail",
    component: () => import("/root/workspace/xtool-community-ssr/pages/discussion/detail/index.vue")
  },
  {
    name: "explore-materials",
    path: "/explore/materials",
    component: () => import("/root/workspace/xtool-community-ssr/pages/explore/materials/index.vue")
  },
  {
    name: "explore-occasions",
    path: "/explore/occasions",
    component: () => import("/root/workspace/xtool-community-ssr/pages/explore/occasions/index.vue")
  },
  {
    name: "share-useFormIntegration",
    path: "/share/useFormIntegration",
    component: () => import("/root/workspace/xtool-community-ssr/pages/share/useFormIntegration.ts")
  },
  {
    name: "subscription-pay-result",
    path: "/subscription/pay-result",
    component: () => import("/root/workspace/xtool-community-ssr/pages/subscription/pay-result.vue")
  },
  {
    name: "activity-christmas",
    path: "/activity/christmas",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/christmas/index.vue")
  },
  {
    name: "activity-halloween",
    path: "/activity/halloween",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/halloween/index.vue")
  },
  {
    name: "activity-valentine",
    path: "/activity/valentine",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/valentine/index.vue")
  },
  {
    name: "activity-mothersday",
    path: "/activity/mothersday",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/mothersday/index.vue")
  },
  {
    name: "collection-allcollections",
    path: "/collection/allcollections",
    component: () => import("/root/workspace/xtool-community-ssr/pages/collection/allcollections.vue")
  },
  {
    name: "contest-ContentMain",
    path: "/contest/ContentMain",
    component: () => import("/root/workspace/xtool-community-ssr/pages/contest/ContentMain/index.vue")
  },
  {
    name: "activity-weeklytasks",
    path: "/activity/weeklytasks",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/weeklytasks/index.vue")
  },
  {
    name: "aimake-AIBackgroundRemover",
    path: "/aimake/AIBackgroundRemover",
    component: () => import("/root/workspace/xtool-community-ssr/pages/aimake/AIBackgroundRemover.vue")
  },
  {
    name: "activity-bestprojects",
    path: "/activity/bestprojects",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/bestprojects/index.vue")
  },
  {
    name: "activity-thanksgiving",
    path: "/activity/thanksgiving",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/thanksgiving/index.vue")
  },
  {
    name: "aimake-ThreeDEffectEngraving",
    path: "/aimake/ThreeDEffectEngraving",
    component: () => import("/root/workspace/xtool-community-ssr/pages/aimake/ThreeDEffectEngraving.vue")
  },
  {
    name: "activity-christmas-2023",
    path: "/activity/christmas-2023",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/christmas-2023/index.vue")
  },
  {
    name: "easyset-materials-shared-id",
    path: "/easyset/materials-shared/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/easyset/materials-shared/[id].vue")
  },
  {
    name: "easyset-materials-shared-const",
    path: "/easyset/materials-shared/const",
    component: () => import("/root/workspace/xtool-community-ssr/pages/easyset/materials-shared/const.ts")
  },
  {
    name: "activity-thanksgiving-draw-hook",
    path: "/activity/thanksgiving-draw/hook",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/thanksgiving-draw/hook.ts")
  },
  {
    name: "discussion-reply-replyId-id",
    path: "/discussion/reply/:replyId()/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/discussion/reply/[replyId]/[id].vue")
  },
  {
    name: "activity-thanksgiving-draw",
    path: "/activity/thanksgiving-draw",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/thanksgiving-draw/index.vue")
  },
  {
    name: "contest-SingleActivityItem",
    path: "/contest/SingleActivityItem",
    component: () => import("/root/workspace/xtool-community-ssr/pages/contest/SingleActivityItem/index.vue")
  },
  {
    name: "project-detail-type-id",
    path: "/project/detail/:type()/:id()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/project/detail/[type]/[id]/index.vue")
  },
  {
    name: "activity-derivative-creation",
    path: "/activity/derivative-creation",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/derivative-creation/index.vue")
  },
  {
    name: "easyset-studio-material-list",
    path: "/easyset/studio-material-list",
    component: () => import("/root/workspace/xtool-community-ssr/pages/easyset/studio-material-list/index.vue")
  },
  {
    name: "activity-temp-page-actId-confId",
    path: "/activity/temp-page/:actId()/:confId()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/activity/temp-page/[actId]/[confId].vue")
  },
  {
    name: "project-detail-type-id-comment-commentId",
    path: "/project/detail/:type()/:id()/comment/:commentId()",
    component: () => import("/root/workspace/xtool-community-ssr/pages/project/detail/[type]/[id]/comment/[commentId].vue")
  }
]