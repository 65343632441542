import { LoginBanner } from '#components';
import PassportClient, { AREA, CLIENT_ENV, type DefaultConfigType, DOMAIN_SPACE, PAGE_MODE, XTOOL_PLATFORM } from '@makeblock/passport-client';
import dayjs from 'dayjs';
import { createApp } from 'vue';
import { useForceLogin } from '~/composables/force-login';
import { sendEvent } from '~/composables/useGA4Tracking';
import { useUserInfo } from '~/stores/user';
import type { Fn } from '~/types/fns';
import { GA4Event } from '~/types/ga4';

export class UaaClient {
  #client: PassportClient;
  #userStore: ReturnType<typeof useUserInfo>;

  #deviceFlags: ReturnType<typeof useDeviceFlags>;
  #forceLogin: ReturnType<typeof useForceLogin>;

  get token() {
    return this.#client?.token;
  }

  get client() {
    return this.#client;
  }

  constructor() {
    this.#client = this.#createPassportClient();
    this.#userStore = useUserInfo();
    this.#deviceFlags = useDeviceFlags();
    this.#forceLogin = useForceLogin();

    if (import.meta.client) {
      // 监听登录弹窗关闭事件
      window.addEventListener('message', (event) => {
        if (event.data?.action === 'close-passport-modal' && event.data?.reason === 'click-close-btn') {
          sendEvent(GA4Event.LOGIN_POP_CLOSE, {
            popup_type: this.#deviceFlags.isRegistered.value ? 'default' : 'new user',
          });
        }
      });
    }
  }

  #createPassportClient() {
    const config: DefaultConfigType = {
      domainSpace: DOMAIN_SPACE.XTOOL,
      platform: XTOOL_PLATFORM.ATOMM,
      mode: PAGE_MODE.WIN,
      lang: 'en',
      area: AREA.US,
      height: 700,
      preFetch: true,
      adaptWindow: true,
      loading: true,
      oneTap: {
        enabled: true,
      },
    };
    if (import.meta.client) {
      const div = document.createElement('div');
      const vm = createApp(LoginBanner);
      vm.mount(div);
      config.advertisement = div;
      config.advertisementPosition = 'left' as any;
    }
    const client = new PassportClient(config);
    if (import.meta.env.VITE_PASSPORT_ENV === 'prod') {
      client.env = CLIENT_ENV.PROD;
      client.lang = 'en';
      client.area = AREA.US;
    } else if (import.meta.env.VITE_PASSPORT_ENV === 'test') {
      client.env = CLIENT_ENV.TEST;
      client.lang = 'zh';
      client.area = AREA.CN;
    } else if (import.meta.env.VITE_PASSPORT_ENV === 'testus') {
      client.env = CLIENT_ENV.TEST;
      client.lang = 'en';
      client.area = AREA.US;
    } else if (import.meta.env.VITE_PASSPORT_ENV === 'preus') {
      client.env = CLIENT_ENV.PRE_PROD;
      client.lang = 'en';
      client.area = AREA.US;
    } else {
      client.env = CLIENT_ENV.DEV;
      client.lang = 'zh';
      client.area = AREA.CN;
    }

    if (import.meta.client) {
      client.on('oneTap', async () => {
        try {
          await this.#loginAspect();
        } finally {
          window.location.reload();
        }
      });
    }

    return client;
  }

  openModal(cb?: Fn<[{ data: any; reload: (url?: string) => void }], void>) {
    this.#forceLogin.markForceLogin();

    sendEvent(GA4Event.LOGIN_POP_SHOW, {
      popup_type: this.#deviceFlags.isRegistered.value ? 'default' : 'new user',
    });

    this.#client.openModal(async (data, reload) => {
      console.log('[uaa]', '触发登录');
      try {
        await this.#loginAspect();
      } finally {
        cb?.({ data, reload });
      }
    });
  }

  login(url?: string) {
    this.openModal(({ reload }) => {
      reload(url);
    });
  }

  logout() {
    return this.#client.logout();
  }

  async fetchUserInfo() {
    const res: any = await this.#client.userInfo();
    return res;
  }

  getUrl(url: string) {
    return this.#client.getUrl(url);
  }

  async #loginAspect() {
    const isNewUser = !this.#deviceFlags.isRegistered.value;

    await this.#userStore.init();

    sendEvent(GA4Event.DESIGN_FIND_LOGIN, {
      uid: this.#userStore.myInfo.id,
      cid: this.#deviceFlags.cid.value,
    });

    if (!isNewUser) {
      return;
    }
    this.#deviceFlags.markRegistered();

    // 判断是否为新用户注册
    const t1 = dayjs(this.#userStore.myInfo.createTime * 1000)
      .add(30, 'second')
      .unix();
    const t2 = dayjs().unix();
    if (t1 < t2) {
      return;
    }
    localStorage.setItem('new-user-toast', 'true');
  }
}
