<template>
  <ConfigProvider>
    <a-extract-style>
      <a-config-provider :theme="antThemeConfig">
        <NuxtLayout>
          <NuxtLoadingIndicator :color="'repeating-linear-gradient(to right, #FF0035 0%, #FF4069 50%, #FF6699 100%)'" :throttle="0" :height="2" />
          <NuxtPage :keepalive="keepAlive" :max="max" />
        </NuxtLayout>
      </a-config-provider>
    </a-extract-style>
  </ConfigProvider>
</template>

<script setup lang="ts">
import { antThemeConfig } from '@/config/ant-theme';
import { keepAlive, max } from '@/config/keep-alive';
import Shen from '@/utils/shen';
import { ConfigProvider } from '@atomm/atomm-pro';
import { appInit } from '~/composables/app-init';
import { usePageLeave } from '~/utils/shen/pageLeave';

// 在根组件中捕获错误，防止应用崩溃，返回 false
onErrorCaptured((err, instance, info) => {
  console.error(err);
  console.log('instance :>> ', instance);
  console.log('info :>> ', info);
  return false;
});

message.config({ top: '80px' });

// 初始化时 cid 写入 cookie
useDeviceFlags();

usePageLeave();

// 将保存埋点参数的实例挂载到 window 对象
Shen.mountToWindow();

await appInit();
</script>
