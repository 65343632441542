import { createFromIconfontCN } from '@ant-design/icons-vue';

/**
 * 更新图标分3步:
 * 1. 下载最新压缩包
 * 2. 替换public/icon-font文件夹中的iconfont.js文件
 * 3. 参数替换成当前时间：?t=xxxxx
 */
export default defineNuxtPlugin((nuxtApp) => {
  const IconFont = createFromIconfontCN({
    scriptUrl: [
      '/icon-font/iconfont.js' + '?t=20250317', // ssr
      '//at.alicdn.com/t/c/font_4437892_ifve4y3fkqc.js', // Easyset
      '//at.alicdn.com/t/c/font_4846123_s4jb0id1nx.js', // Easyset
      '//at.alicdn.com/t/c/font_4461009_4swmfwxlj5j.js', // AImake
      '//at.alicdn.com/t/c/font_4846123_fh5qqr2phed.js', // atomm
    ],
  });
  nuxtApp.vueApp.component('IconFont', IconFont);
});
