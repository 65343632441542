export default class Shen {
  /**
   * 单例模式: 这个类只返回同一个实例对象
   */
  static instance: Shen | null = null;
  // get 定义的方法, 在调用时不需要加括号()
  static get Instance() {
    if (!this.instance) {
      this.instance = new Shen();
    }
    return this.instance;
  }

  /**
   * 将实例挂载到 window 对象
   */
  static mountToWindow() {
    if (!window) {
      if (import.meta.server) {
        return;
      }
      console.warn('df_shen-error: window is not defined');
      setTimeout(() => {
        this.mountToWindow();
      }, 2000);
      return;
    }
    window.df_shen = this.Instance;
  }

  // 被管理的参数
  params = {
    latest_page_name: '', // 最近一次页面来源
    latest_block_name: '', // 最近一次栏目来源
    latest_keyword: '', // 最近一次关键词来源
    latest_scene_name: '', // 最近一次案例id来源
    latest_contentid_algorithm_version: '', // 最近一次算法版本
    latest_contentid_algorithm_type: '', // 最近一次案例算法类型
  };

  /**
   * 存储&更新参数
   */
  setParams(params: any) {
    this.params = {
      ...this.params,
      ...params,
    };
    // console.log('this.params :>> ', this.params);
  }

  /**
   * 获取参数
   */
  getParams() {
    return this.params;
  }

  /**
   * 清空参数
   */
  clearParams() {
    this.params = {
      latest_page_name: '',
      latest_block_name: '',
      latest_keyword: '',
      latest_scene_name: '',
      latest_contentid_algorithm_version: '',
      latest_contentid_algorithm_type: '',
    };
  }

  sendedList: Record<string, any>[] = [];
  newList: Record<string, any>[] = [];

  addNewBlock(data: Record<string, any>, cb?: () => void) {
    const sendedIndex = this.sendedList.findIndex((item: any) => item.id === data.id);
    const newIndex = this.newList.findIndex((item: any) => item.id === data.id);
    if (sendedIndex === -1 && newIndex === -1) {
      this.newList.push(data);
    }
    cb && cb();
  }

  getNewList() {
    return this.newList;
  }

  // 是否具有需要上报的数据
  hasNewList = () => {
    return this.newList.length > 0;
  };

  setNewList(list: Record<string, any>[]) {
    this.newList = list;
    // console.log('this.newList :>> ', this.newList);
  }

  setSendedList(list: Record<string, any>[]) {
    this.sendedList = this.sendedList.concat(
      list.map((item: any) => ({
        ...item,
        isSended: true,
      })),
    );
    // console.log('this.sendedList :>> ', this.sendedList);
  }

  clearList() {
    this.newList = [];
    this.sendedList = [];
  }
}
