import { isNil } from 'lodash-es';

const storageMap = new Map<string, any>();
export function useStorage<T = any>(key: string, initialValue?: T) {
  let storage = storageMap.get(key);
  if (isNil(storage)) {
    const [use] = useContext(() => {
      const state = ref<any>(initialValue);
      watch(
        () => state.value,
        () => {
          localStorage.setItem(key, JSON.stringify({ value: state.value }));
        },
        { deep: true },
      );
      return state;
    });
    storage = { use, inited: false };
    storageMap.set(key, storage);
  }

  const state = storage.use() as Ref<T>;

  if (getCurrentInstance()) {
    onMounted(() => {
      if (!storage.inited) {
        state.value = parseJSON(localStorage.getItem(key), { defaultValue: { value: state.value } }).value;
        storage.inited = true;
      }
    });
  }
  return state;
}
