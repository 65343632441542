import { createMessageClient, setupFirebaseMessaging } from '@makeblock/message-client-vue';

export default defineNuxtPlugin((nuxtApp) => {
  const { $client } = nuxtApp;

  const messageClient = createMessageClient({
    ...($client as any).client.getMessageClientState(),
    openLocalMessage: false,
    openNotifyListener: true,
    passportStateListener(cb: (data: any) => void) {
      ($client as any).client.onStateListener(cb);
    },
  });
  setupFirebaseMessaging();

  messageClient.on('openAllMessage', () => {
    console.log('openAllMessage');
    navigateTo('/notice-center');
  });

  const getUnreadCount = () => {
    return messageClient.messages.filter((item: any) => item.status === 'unread').length;
  };
  const unreadCount = useState('message-unread-count', getUnreadCount);
  messageClient.on('unreadCount', () => {
    unreadCount.value = getUnreadCount();
  });

  return {
    provide: {
      messageClient,
    },
  };
});
