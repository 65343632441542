// keep-alive设置
const includes = ['featured', 'search-result', 'collection', 'column', 'all', 'easyset'];
const excludes = [''];

const keepAlive = {
  include: includes.join(','),
  exclude: excludes.join(','),
};
const max = 10;

export { keepAlive, max };
