import { type EffectScope } from 'vue';

export function useContext<F extends (...args: any) => any>(stateFactory: F): [F, EffectScope] {
  let initialized = false;
  let state: any;
  const scope = effectScope(true);

  const use = (...args: any) => {
    if (!initialized) {
      state = scope.run(() => stateFactory(...args));
      initialized = true;
    }
    return state;
  };
  return [use as F, scope];
}
